<template>
  <div>
    <div>
      <navbar />
    </div>
    <!-- 中间内容 -->
    <div class="middle">
      <div class="middle-head">
        <div class="head-left">
          <span class="left" @click="goOnlineExpert">Home</span>
          <span class="arrow">></span>
          <span class="left-frite">Terms & Conditions</span>
        </div>
      </div>
      <div class="content">
        <div class="content-head">
          <h2>Terms & Conditions</h2>
          <p>Last modification date: January 29th, 2024</p>
        </div>

        <div class="content-box">
          <h4>TERMS OF USE FOR Zest Wisdom</h4>
          <p><b>Please read these terms of use (the “Terms”) carefully before using the ZestWisdom website and/or mobile application.</b></p>
          <br>
          <p>By clicking to accept, access and use this website, you are deemed to have accepted and be legally bound with these Terms of Use between you and WORLD OF METAPHYSICS PTE LTD. WORLD OF METAPHYSICS PTE LTD is also referred to as “we”, “our” and/or “us”. We reserve our right to change the Terms of Use at anytime. Any change to the terms of use will be posted on this website or other means of communication and your use of this website shall constitute your agreement to the amended Terms of Use.</p>
          <br>
          <p>ZestWisdom.com is a web platform that provides a marketplace that brings advice-seekers and independent, metaphysics consultant (“Advisor(s)”) together through text or video. It allows You to search for, engage and submit questions to Advisor who provide personalized metaphysic advice. When using the Service, you will have access to various design, template, and feature provided in this platform.</p>
          <br>
          <p>By using the Service in this platform, you represent and warrant that you have the full right, power and authority to agree to and be bound by these Terms of Use and to fully perform all of your obligations hereunder.</p>
          <br>
          <p>Where you sign up for this Service on behalf of an organization or entity, you represent and warrant that you are an authorized representative of that organization or entity and you are authorized to enter into this agreement on behalf of the organization or entity and to be bound by the Terms of Use.</p>
          <br>
          <p>By using the Service, you also represent that you have reached the age of 13 years old or such applicable legal age. Children may not access or use the Service unless their use is directly authorized by their parent, guardian or another authorized adult such as a teacher who agrees to be bound by these Terms of Use. For purposes of these Terms, a child is a person under the age of 13 or the minimum legal age required to provide consent for processing of personal data in the country where the child is located.</p>
          <br>
          <p>You represent and warrant that all registration information provided to use is true and accurate to the best of your knowledge and undertake to inform us of any change of information as soon as reasonably practicable. You are responsible and liable for maintaining control over your account, including the confidentiality of your username and password, and are solely responsible and liable for all activities that occur on or through your account and all Authorized Users’ accounts, whether authorized by you or not.</p>
          <br>
          <p>Authorized Users are your employees and other persons associated with your WORLD OF METAPHYSICS PTE LTD account.</p>
          <br>
          <p>You shall agree to treat your access credentials (including username and password) as confidential information, and not to disclose such information to any third party.</p>
          <br>
          <p>Subject to your compliance with these Terms of Use, you are granted a non-exclusive, limited, non-transferable, non-sublicensable license, freely revocable license to access and use the Service for personal use or business. We reserves all rights not expressly granted under these Terms of Use. Each user must have a unique account and you are responsible for any activity conducted on your account.</p>
          <br>
          <p>Except as otherwise expressly authorized in these Terms of Use, the contents of this website shall not be reproduced, republished, uploaded, posted, transmitted or otherwise distributed in any way, without the prior permission of WORLD OF METAPHYSICS PTE LTD.</p>
          <br>
          <p>Except as otherwise expressly authorized in these Terms, you will not, and will ensure your Authorized Users do not, and will not encourage or assist third parties to: (i) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, know-how, or algorithms relevant to the Service (except to the extent that such a restriction is impermissible under applicable law); (ii) provide, sell, resell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Service; and (iii) copy, modify, create derivative works of, or remove proprietary notices from the Service.</p>
          <br>
          <h5>1. Proprietary Rights</h5>
          <p>This website is owned and operated by WORLD OF METAPHYSICS PTE LTD. The materials located on this website including the statistical data, reports, charts, graphs, information and documentation (the Contents), are protected by copyright, trademark and other intellectual property rights.
          </p>
          <br>
          <h5>2. Restrictions on Use of Contents</h5>
          <p>Except as otherwise provided, the Contents of this website shall not be reproduced, republished, uploaded, posted, transmitted or otherwise distributed in any way, without the prior permission of WORLD OF METAPHYSICS PTE LTD. To use any parts of the Contents of this website, the user must seek permission in writing, stating the Contents for use; intent of use; manner of use; time frame of use and identity of the user. WORLD OF METAPHYSICS PTE LTD reserves the right to refuse permission without declaring reason(s), and/or if information submitted by the user is deemed insufficient. Modification of any of the Contents or use of the Contents for any other purpose will be a violation of WORLD OF METAPHYSICS PTE LTD’s copyright and other intellectual property rights. Graphics and images on this website are protected by copyright and may not be reproduced or appropriated in any manner without written permission of WORLD OF METAPHYSICS PTE LTD. Provided permission is expressly granted by WORLD OF METAPHYSICS PTE LTD, the Contents are available if:</p>
          <ul class="list-category">
            <li>Due acknowledgement is given to “WORLD OF METAPHYSICS PTE LTD”, citing the sources for the relevant data extracted</li>
            <li>The Contents are used for informational and non-commercial or personal use only and will not be copied or posted on any network computer or broadcast in any media; and no alterations, additions or modifications of the Contents are made in any way.</li>
          </ul>
          <p>Any request to reproduce the Contents from this website for publication or commercial use should be sent to support@zestwisdom.com.</p>
          <br>
          <h5>3. Feedbacks, comments, or suggestions</h5>
          <p>WORLD OF METAPHYSICS PTE LTD welcome all feedbacks, comments, or suggestions (collectively referred to as “Feedback”) from you so that we can improve your user experience. You hereby irrevocably transfer and assign all rights, ownership and interests of the Feedback and all related intellectual property rights to WORLD OF METAPHYSICS PTE LTD. You hereby waive any request for acknowledgment or compensation based on any Feedback, or any modifications based on any Feedback. </p>
          <br>
          <h5>4. Reservation of Rightss</h5>
          <p>WORLD OF METAPHYSICS PTE LTD owns all right, title, and interest in the Services, and you own all right, title, and interest in any application(s) and/or material(s) that are developed by you on the Services or uploaded to the Services by you (“User Content”). Except as expressly set forth in these Terms of Use, each party retains all right, title, and interest in and to its intellectual property rights. All rights not expressly granted are reserved, and no license, covenant, immunity, transfer, authorization, or other right will be implied, by reason of statute, estoppel, or otherwise, under these Terms.</p>
          <br>
          <h5>5. Third Party services</h5>
          <p>
            Your use of any third party resources provided in the website or Services is subject to the third party’s terms and conditions. WORLD OF METAPHYSICS PTE LTD makes no warranties or representations with regards to the third party resources and expressly disclaims all liability arising from your use of any third party resources. You are advised to read and observe the terms and conditions of such applicable third party’s resources.
            <br><br>For the avoidance of doubt, WORLD OF METAPHYSICS PTE LTD may engage third parties as service providers to the Services provided in the website.
            <br><br>This website may contains hyperlinks to websites which are not maintained by WORLD OF METAPHYSICS PTE LTD. WORLD OF METAPHYSICS PTE LTD is not responsible for the contents of those websites and shall not be liable for any damages or loss arising from access to those websites. Use of the hyperlinks and access to such websites are entirely at your own risk.
          </p>
          <br>
          <h5>6. Payment and billing</h5>
          <p>Your subscription can be cancelled at any time. The cancellation will be effective at the end of the current subscription period. No refund will be made upon cancellation. The subscription fee does not include taxes and you agree to pay any taxes, as applicable by the law.</p>
          <br>
          <h5>7. Disclaimer of warranties and liability</h5>
          <p>
            The contents of this website are provided on an “as is” basis without warranties of any kind. To the fullest extent permitted by law, WORLD OF METAPHYSICS PTE LTD does not warrant and hereby disclaims any warranty as to the accuracy, correctness, reliability, timeliness, non-infringement, title, merchantability or fitness for any particular purpose of the contents of this website and that this website or any functions associated therewith will be without defect, uninterrupted or error-free. 
            <br><br>In the event of any loss or corruption of any data associated with the Services, WORLD OF METAPHYSICS PTE LTD will use commercially reasonable efforts to restore the lost or corrupted data from the latest relevant backup maintained by WORLD OF METAPHYSICS PTE LTD. Except for the foregoing, WORLD OF METAPHYSICS PTE LTD will not be responsible for any loss, destruction, alteration, unauthorized disclosure or corruption of any data as a result of direct or indirect use of the website, including but not limited to any damage or loss suffered as a result of reliance on the contents contained in or available from the website.
          </p>
          <br>
          <h5>8. Disclaimer of damages and limitation of liability</h5>
          <p>To the maximum extent permitted by applicable law, in no event will WORLD OF METAPHYSICS PTE LTD and its affiliates be liable for any incidental, indirect, special, punitive, consequential or similar damages or liabilities whatsoever arising out of WORLD OF METAPHYSICS PTE LTD services except to the extent of a final judicial determination that such damages were a result of WORLD OF METAPHYSICS PTE LTD’s gross negligence, fraud, willful misconduct or intentional violation of law. Notwithstanding the foregoing, in no event will the liability of WORLD OF METAPHYSICS PTE LTD and its affiliates exceed the amount of the subscription fees paid by you to WORLD OF METAPHYSICS PTE LTD under these Terms of Use in the twelve-month period immediately preceding the event giving rise to the claim for liability.</p>
          <br>
          <h5>9. Notices</h5>
          <p>All required notices to you will be sent to the email address associated with your account or posting to the website or through other legally permissible means</p>
          <br>
          <h5>10.	Waiver</h5>
          <p>WORLD OF METAPHYSICS PTE LTD’s express waiver or failure to enforce any provision of these Terms of Use shall in no way be construed to be a present or future waiver of such provision nor affect WORLD OF METAPHYSICS PTE LTD’s ability to enforce any provision thereafter</p>
          <br>
          <h5>11.	Entire Agreement</h5>
          <p>These Terms of Use and the terms and policies referenced herein constitute the entire agreement between you and WORLD OF METAPHYSICS PTE LTD with respect to the Service. These Terms of Use supersede any prior representations, agreements, or understandings between you and WORLD OF METAPHYSICS PTE LTD, whether written or oral. In the event of inconsistencies, the English version of these Terms of Use will prevail.</p>
          <br>
          <h5>12.	Force Majeure</h5>
          <p>Any delay or failure to perform any obligation under these Terms of Use by WORLD OF METAPHYSICS PTE LTD resulting from a condition beyond its reasonable control, WORLD OF METAPHYSICS PTE LTD  shall not be considered to be in breach of or default, so long as WORLD OF METAPHYSICS PTE LTD  uses commercially reasonable efforts to fulfill its obligations. </p>
          <br>
          <h5>13.	Intellectual property</h5>
          <p>
            Unless otherwise indicated, the website and all content and other materials therein, including, without limitation, the WORLD OF METAPHYSICS PTE LTD  logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, “WORLD OF METAPHYSICS PTE LTD  Contents”) are the proprietary property of WORLD OF METAPHYSICS PTE LTD  or its licensors or users and are protected by Singapore and international intellectual property and copyright laws.
            <br><br>You are granted a limited, non-sublicensable license to access and use the website and Services for personal and informational purposes only. Such license is subject to the Terms of Use and does not include: (a) any resale or commercial use of the website or WORLD OF METAPHYSICS PTE LTD  Contents; (b) modifying or otherwise making any derivative uses of the website or WORLD OF METAPHYSICS PTE LTD  Contents, or any portion thereof; (c) use of any data mining, robots or similar data gathering or extraction methods; (d) downloading (other than the page caching) of any portion of the website, the WORLD OF METAPHYSICS PTE LTD  Contents or any information contained therein, except as expressly permitted on the website; or (e) any use of the website other than for its intended purpose. 
            <br><br>Such unauthorized use may also violate applicable laws, including, without limitation, copyright and trademark laws and applicable communications regulations and statutes. All rights not expressly granted are reserved.
            <br><br>If you believe any material available via the website infringes any intellectual property or copyright you own or control, you may file a notification of such alleged infringement to us by sending an e-mail at the following email address: support@zestwisdom.com
            <br><br>The URL containing the alleged infringing materials should be identified together with supporting documents proving your ownership or control over the said intellectual property or copyrights. We reserve our rights to require you to provide additional supporting materials as we deemed appropriate.
          </p>
          <br>
          <h5>14.	Privacy Policy</h5>
          <p>
            While we do not set out to collect any personally identifiable information on this website especially if you are only browsing the website, access to the Service will require the submission of some personally identifiable information. The following shall apply:
            <ul class="list-category">
              <li>14.1 "Personal Data" means any data or information, whether true or not, about an individual who can be identified either (a) from that data; or (b) from that data and other information to which WORLD OF METAPHYSICS PTE LTD is likely to have access to. Personal Data excludes business contact information which refers to an individual's name, position name or title, business telephone number, business address, business electronic mail address or business fax number and any other similar information about the individual, not provided by the individual solely for his personal purposes.</li>
              <li>14.2 We may collect, use and disclose the following data about you in the circumstances as set out below:
                <br>-	When you provide Personal Data by filling in online or hardcopy forms to apply for our services and/or using the website
                <br>-	We may keep a record of correspondence in circumstances where you contact WORLD OF METAPHYSICS PTE LTD
                <br>-	Details of your visit to the website including, but not limited to, cookies, location data and other communication data, that you access;
              </li>
              <li>14.3	We may collect the following kinds of Personal Data or such other kinds through the different and other possible channels other than those mentioned above: Full name, Gender, Nationality, Race, Marital Status, Date of Birth, Residential addresses, Personal email addresses, Mobile and telephone numbers;, Photos and videos if applicable, NRIC/WP/FIN/Passport numbers and copies, when needed; and Bank account details [for client to add if any]</li>
              <li>14.4	We may use or disclose the personal data we collect for any of the following purposes:
                <br>-	To carry out contractual obligations and administer Services
                <br>-	To enable our officers, employees, related entities, service providers, vendors, advisors, subcontractors and third-party partners and agents (e.g. government ministries and agencies, training partners, and other SSAs, etc.), to fulfil the obligations/ services as stipulated in your contract with us;
                <br>-	To respond to service or scheme requests and enquiries;
                <br>-	For relationship management and communications;
                <br>-	To keep you updated of our services, programs, events and other relevant information;
                <br>-	For marketing and publicity if you have provided consent for such purposes;
                <br>-	For Human resource management purposes;
                <br>-	Other business and legal purposes, complying with legal and regulatory obligations and requirements, to facilitate research, data analysis, and business planning purposes;
                <br>-	To process your enquiries and other ancillary administrative purposes;
                <br>-	For security, identity verification and safety surveillance and monitoring purposes, including but not limited to, contact tracing;
                <br>-	For internal approval and reporting, accounting, audit, compliance, and record-keeping purposes;
                <br>-	Purposes incidental to each or all of the above or such other necessary purposes.
                <br>-	We may also contact you by any means of communication for which you have provided your contact details.
              </li>
              <li>14.5	You consent to the collection, use and disclosure of your Personal Data for the above mentioned purposes and agree to be bound by the obligations it imposes on you, when you accept this Privacy Policy by continuing to browse the website, continuing to engage with us. In this regard, please ensure that all personal data submitted to us is complete, accurate, true and correct at the time of submission. Failure on your part to do so may result in our inability to provide you with products and services you have requested.</li>
              <li>14.6	Please note that if you do not consent to any of the above business purposes, we may not be able to meet the purposes for which the information was collected.</li>
              <li>14.7	On occasions, we may use third-party partners and agents (e.g. government ministries and agencies, training partners, etc.), to assist us in the use (which includes processing) of your Personal Data. You consent to such use of your Personal Data by continuing to browse the website, or to engage with us.</li>
              <li>14.8	In the course of providing our services, we may transfer Personal Data outside Singapore. If Personal Data is transferred, we will accord the Personal Data a level of protection, which is comparable to the protection accorded under Singapore’s Personal Data Protection Act 2012 ("PDPA").</li>
              <li>14.9	We will cease to retain Personal Data, as soon as it is reasonable to assume that the purpose for collection of such personal data is no longer being served by such retention, and such retention is no longer necessary for legal or business purposes</li>
              <li>14.10	We  may collect personal data via technologies used on our website and web-based applications to make them more user-friendly, effective and secure. Such technologies may lead to data being collected automatically by us or by third parties on our behalf. Such data does not generally, but may, contain a user's personal data. It may include; the date and time of the visit, the referral URL, the pages visited on our website and information about the browser used, cookies, web beacons and tracking links, and web analytics.</li>
              <li>14.11	We implements a variety of security measures to maintain the safety of your submitted information. All electronic storage and transmission of personal data is secured and stored on managed servers with controlled access and appropriate security technologies.</li>
              <li>14.12	Although every reasonable effort has been made to ensure that all personal data will be protected, we cannot be responsible for any unauthorised use or misuse of such information and from risks which are inherent in all internet communications.</li>
              <li>14.13	Your Personal Data will only be expressly used for the purposes listed, and shall not be sold or disclosed to any other third party for any other purposes whatsoever without your consent.</li>
              <li>14.14	You may contact us at support@zestwisdom.com if you have any queries or feedback regarding your Personal Data or this policy.</li>
              <li>14.15	We reserve the right to change this Policy with or without notice from time to time.</li>
              <li>14.16	This Policy is governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction of the Courts of Singapore in any dispute relating to this Policy.</li>
            </ul>
          </p>
          <br>
          <h5>15.	Indemnification</h5>
          <p>
            You agree to defend, indemnify and hold harmless WORLD OF METAPHYSICS PTE LTD and its affiliates from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys’ fees) resulting from your use of the website or violation of these Terms of Use.
          </p>
          <br>
          <h5>16.	Termination</h5>
          <p>
            Notwithstanding any of these Terms of Use, WORLD OF METAPHYSICS PTE LTD reserves the right in its sole discretion to terminate your account and to prevent your future access to and use of the website.
          </p>
          <br>
          <h5>17.	Severability</h5>
          <p>
            If any provision of these Terms of Use shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.
          </p>
          <br>
          <h5>18.	Governing Law</h5>
          <p>
            These Terms of Use shall be governed and construed in accordance with laws of the Republic of Singapore and the parties submit to the exclusive jurisdiction of the Court of Singapore.
          </p>
          <br>
          <h5>19.	Contact Info</h5>
          <p>
            Email Contact: support@zestwisdom.com
          </p>
          <br>
        </div>
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import { useRouter } from "vue-router";
import { Search } from "@element-plus/icons-vue";
import { reactive, onMounted } from "vue";
const router = useRouter();

onMounted(() => {
  window.scrollTo(0, 0);
});
//搜索
const getSearch = (val) => {
  console.log(val, "val");
  router.push("/onlineExpert?q=" + val);
};
const state = reactive({
  searchValue: "",
});
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .middle {
    width: 100%;
    background: #f5f5f5;
    padding-bottom: 20px;
    font-family: "Open Sans", Arial, sans-serif;

    .middle-head {
      line-height: 30px;
      padding: 0 10rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ffff;
      border-color: rgba(251, 249, 249, 0.1);
      font-size: 15px;

      .head-left {
        margin-left: 10px;
        color: black;
        font-size: 15px;

        .left {
          cursor: pointer;
        }
        .arrow {
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 500;
        }

        .left-frite {
          color: rgb(90, 181, 209);
        }
      }

      .head-right {
        margin-right: 8px;
        margin-top: 3px;
        display: flex;

        .search-input {
          width: 300px;
          margin-bottom: 5px;
        }

        :deep(.el-input__wrapper) {
          background-color: transparent !important;
        }

        :deep(.el-input__inner) {
          color: #ffffff;
        }

        span {
          font-size: 20px;
          cursor: pointer;
          margin-left: 8px;
          margin-right: 15px;
          padding-right: 8px;
          border-right: 1px solid rgba(251, 249, 249, 0.1);
        }
      }
    }

    .content {
      width: 900px;
      margin: auto;
      margin-top: 30px;
      background: #fafafa;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px;

      .content-head {
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        padding: 0 15px;
        background: #fafafa;

        h2 {
          color: #000000;
          font-size: 20px;
        }

        p {
          font-size: 15px;
          color: #000000d9;
        }
      }

      .content-box {
        padding-right: 15px;
        h4 {
          line-height: 40px;
          margin-left: 20px;
          color: #000000d9;
        }

        .faq-list {
          margin-left: 40px;
          margin-bottom: 20px;
          box-sizing: border-box;
          display: block;
          column-count: 2;
          color: #eedf11;

          li {
            line-height: 30px;
            // margin: 10px 0;
            font-size: 15px;
            list-style: decimal;
            cursor: pointer;
          }
        }

        h4 {
          line-height: 45px;
          margin-left: 20px;
          color: #000000d9;
        }

        p {
          line-height: 20px;
          margin-left: 20px;
          color: #000000d9;
          font-size: 15px;
        }

        h5 {
          line-height: 30px;
          margin-left: 20px;
          color: #000000d9;
        }

        .list-category {
          margin-left: 40px;

          li {
            font-size: 15px;
            margin: 10px 0;
          }
        }

        .list-category1 {
          margin-left: 40px;
          box-sizing: border-box;
          display: flex;
          list-style: none;
          flex-direction: column;
          padding-bottom: 10px;

          li {
            font-size: 15px;
            color: #ffffff;
            position: relative;
            margin: 10px 0;
            margin-left: 15px;
            padding: 0 0 0 10px;
          }
        }

        .list-category1 li::before {
          content: counters(firstLevel, "") "." counters(secondLevel, ".") ".";
          position: absolute;
          left: 0px;
          transform: translateX(-100%);
        }
      }
    }
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .middle {
    width: 100%;
    background: #f5f5f5;
    padding-bottom: 20px;
    font-family: "Open Sans", Arial, sans-serif;

    .left {
      margin-left: 10px;
      cursor: pointer;
      font-size: 15px;
    }
    .arrow {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 15px;
      font-weight: 500;
    }

    .left-frite {
      font-size: 15px;
      color: rgb(90, 181, 209);
    }

    .content {
      padding: 15px;

      .content-head {
        margin: 20px 0;

        h2 {
          color: #000000;
        }
      }

      .content-box {
        background: #ffffff;
        border-radius: 6px;
        padding: 5px;
        h4,
        h5 {
          margin: 10px 0;
          font-size: 16px;
        }
        p {
          font-size: 15px;
          color: #000000d9;
        }
        .list-category {
          li {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
